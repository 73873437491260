* {
    --correct-color: #15A0A2;
    --incorrect-color: #FE4A49;
    --diacritic-color: var(--text-color);
}

.correct {
    color: var(--correct-color)
}

.incorrect {
    color: var(--incorrect-color);
}

.diacritic {
    color: var(--diacritic-color);
}