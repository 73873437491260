.keyboard-letter-list {
    list-style: none;
    display: flex;
    flex-direction: row-reverse;
    flex-wrap: wrap;
    justify-content: center;
    margin: 0 auto;
    text-align: center;
}

