#input-form-container {
    height: 80%;
}

.input-form {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    animation: fadeIn var(--default-fade-in-len);
  }

.arabic-user-input {
    direction: rtl;
}

input {
    display: block;
    padding: 1%;
    font-size: x-large;
    width: 50%;
    max-width: 150px;
    border-radius: 5px;
    outline: none;
    border: 2px solid var(--accent-color-2);
    background-color: var(--background-color);
    color: var(--accent-color-1);
    margin: 2%;
}

#keyboard-button {
    font-size: xx-large;
}

#keyboard-container {
    margin-top: 2%;
    margin-bottom: 2%;
}

.hide-keyboard-button {
    text-decoration: line-through;
    text-decoration-color: maroon;
    text-decoration-thickness: 3px;
}

.keyboard-hidden {
    visibility: hidden;
}
