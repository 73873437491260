@keyframes fadeIn {
  from {opacity: 0;}
  to {opacity: 1;}
}

@keyframes fadeOut {
  from {opacity: 1;}
  to {opacity: 0;}
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  --background-color: #1F2A33;
  --text-color: #AFCFD4;
  --accent-color-1: #FFCB47;
  --accent-color-2: #8AA1B1;
  --title-font: 'Della Respira', serif;
  --body-font: 'Carme', sans-serif;
  --arabic-font: 'Noto Sans Arabic', sans-serif;
  --default-fade-in-len: .2s;
}

html, body, #root {
  min-height: 100vh;
  background-color: var(--background-color);
}

p {
  font-family: var(--body-font);
  font-size: larger;
  line-height: 170%;
  color: var(--text-color);
}

.arabic {
  font-family: var(--arabic-font);
  font-weight: 200;
}

.App {
  min-height: 100vh;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 550px;
  margin: 0 auto;
}

/* button styling */

button {
  all: unset;
  width: 125px;
  height: 50px;
  background-color: var(--background-color);
  border-radius: 5px;
  color: var(--text-color);
  font-family: var(--body-font);
  border: 1px solid var(--accent-color-1);
  margin: 2%
}

button:hover {
  cursor: pointer;
  background-color: var(--accent-color-1);
  color: var(--background-color);
}

button:disabled {
  visibility: hidden;
}

button:disabled:hover {
  visibility: hidden;
}

/* hide elements */

.invisible {
  display: none !important;
}

/* header */
header {
  width: 100%;
  max-width: 550px;
}

h1 {
  font-family: 'Della Respira';
  margin: 15px;
  color: var(--text-color);
  display: inline-block;
  text-align: center;
}