#spinner {
    position: absolute;
    top: 0;
    margin-top: 50%;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    height: 75px;
    width: 75px;
    border: 10px solid;
    border-radius: 100%;
    border-color: var(--accent-color-2);
    border-top-color: var(--correct-color);
    border-width: 15px;
    transition: all .2s;
    animation-name: spin;
    animation-duration: 2000ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear; 
    }

    @keyframes spin {
        from {
            transform:rotate(0deg);
        }
        to {
            transform:rotate(360deg);
        }
    }