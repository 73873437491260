.letter {
    margin: 5px;
    padding-bottom: 5px;
    padding-top: 5px;
    font-size: x-large;
    border: 1px solid var(--accent-color-1);
    border-radius: 5%;
    width: 45px;
    color: var(--text-color)
}

.letter:hover {
    cursor: pointer;
    background-color: var(--text-color);
    color: var(--background-color)
}

.selected {
    background-color: var(--accent-color-1) !important;
    color: var(--background-color) !important
}

.not-selected {
    background-color: var(--background-color) !important;
    color: var(--text-color) !important;
}

@media only screen and (max-width: 815px) {
    .letter:hover {
        color: var(--text-color);
        background-color: var(--background-color)
        }
}