.start {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 90vh;
    width: 90%;
    justify-content: space-around;
    font-size: larger;
    line-height: 170%;
  }

.start i {
  font-size: x-large;
  color: var(--accent-color-1);
}

/* footer */

footer {
  border-top: 1px solid var(--text-color);
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  /* margin-top: auto; */
  /* max-height: 30px; */
  padding-top: 3px;
  padding-bottom: 5px;
}

a {
  display: inline-block;
  text-decoration: none;
  font-family: var(--body-font);
  color: var(--text-color);
  line-height: 30px;
}

a:hover {
  cursor: pointer;
}

@media only screen and (min-height: 700px) {
  footer {
    /* margin-top: auto; */
    max-height: 30px;
    margin-bottom: 0px;
  }

  a {
    line-height: 15px;
    padding-top: 5px;
  }
}