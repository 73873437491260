.menu-form-container {
    position: relative;
    max-width: 98%;
    height: 75%;
}

.menu-form {
    display: flex;
    flex-direction: column;
    height: 100%;
    align-items: center;
    justify-content: space-evenly;
    animation: fadeIn var(--default-fade-in-len);
}

.time-to-fade {
    animation: fadeOut 1s;
    animation-fill-mode: forwards;
}