.dictation-form {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    width: 96%;
    justify-content: space-evenly;
  }

  #change-letters-pronounce-word-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    height: 20%;
  }
